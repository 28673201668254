'use client';

import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import React, { FC } from 'react';

const AuthProvider: FC<{
  children: React.ReactNode;
  session: Session | null;
}> = ({ children, session = null }) => {
  return <SessionProvider session={session}>{children}</SessionProvider>;
};

export default AuthProvider;
