import { SettingsContext } from '@devias/contexts/settings';
import type { SettingsContextValue } from '@devias/contexts/settings';
import * as React from 'react';

export function useSettings(): SettingsContextValue {
  const context = React.useContext(SettingsContext);

  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }

  return context;
}
