import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/bundles/auth-devias/provider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/app/src/devias/components/core/analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["I18nProvider"] */ "/app/src/devias/components/core/i18n-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocalizationProvider"] */ "/app/src/devias/components/core/localization-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SettingsButton"] */ "/app/src/devias/components/core/settings/settings-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/src/devias/components/core/theme-provider/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/devias/components/core/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserContext","UserProvider"] */ "/app/src/devias/contexts/auth/custom/user-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SettingsProvider"] */ "/app/src/devias/contexts/settings.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/lib/loaders/circle/style.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/app/src/devias/styles/global.css");
